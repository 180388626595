import React from 'react';
import '../styles/Certificates.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';


const certificateData = [
  // {
  //   imageUrl: "./images/android.jpg",
  //   title: "Android development course1"
  // }, 
  {
    imageUrl: "./images/tcps2_core_certificate - Pabita.jpg",
    title: "TCPS 2: CORE 2022 - Research Ethics"
  },
  {
    imageUrl: "./images/figma-essential-training.jpg",
    title: "Figma Essential Training"
  },
  {
    imageUrl: "./images/ios.jpg",
    title: "IOS 17 Training"
  },
];

const CertificatesSection = () => {

  return (
    <Container style={{ paddingBottom: '80px', paddingTop: '80px', backgroundColor: "#f2f2f2" }} id='myCertificates'>
      <h2 className='certificate-title'>Certificates</h2>
      <Row className='certificateRow'>
        {certificateData.map((certificate, index) => (
          <Col key={index} className="certificateContainer">
            <img
              src={certificate.imageUrl}
              alt="Certificate"
              className="certificateImage"
            />
            <p>{certificate.title}</p>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default CertificatesSection;