import React from 'react';
import '../styles/DesignProjectDetailsPage.css'
import { Row, Col } from 'react-bootstrap';

const DesignProjectDesignSystemPage = () => {

    return (
        <div className='DetailsPage'>
            <div className='detailsPageCover'>
                <img
                    src="/images/Website Mockup - Lovesick Girls MV.png"
                    alt="Hello Fresh logo"
                    className="detail-page-image"
                />
            </div>
            <div className='detailsPageHeader'>
                <h2 className='details-page-title'>Design System for Music Video - "Lovesick Girls"</h2>
                <p style={{ fontSize: "16px", width: "70%" }}>Created a design system, moodboard and prototype for a Kpop Music Video.
                </p>

                <div>
                    <p><strong>Context:</strong> Design System</p>
                    <p><strong>Project Duration:</strong> 1 Week</p>
                    <p><strong>Team Size:</strong> 1 (Individual) </p>
                    <p><strong>Tools Used:</strong> Figma, Figjam, Miro and Canva</p>
                </div>


                <h3 style={{ color: "#5c8f18", marginBottom: "0px" }}>Overview</h3>
                <p>I love listening to K-pop music, and I’m always fascinated by the visuals and different elements shown in the music videos. One of the MVs I love is Blackpink’s ‘Lovesick Girls.’ The visuals, color contrast, and aesthetics are so well put together. To practice the design system I learned in class, I started by creating a mood board based on the music video, which helped me understand the different color gradients, typography, and imagery. Then, I created my design system by referencing this mood board, and finally, I ideated and designed a possible website.</p>

                <h3 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "50px" }}>Moodboard</h3>
                <img
                    src="/images/Moodboard Images.png"
                    alt="Hello Fresh logo"
                    className="design-image"
                />

                <h3 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "50px" }}>Design System</h3>
                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>Logo</h4>
                <img
                    src="/images/Design System/Logo.png"
                    alt="Blackpink logo"
                    className="design-image"
                />

                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>Color Palette</h4>
                <img
                    src="/images/Design System/Color Palette.png"
                    alt="Blackpink logo"
                    className="design-image"
                />

                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>Typography</h4>
                <img
                    src="/images/Design System/Typography-1.png"
                    alt="Typography 1"
                    className="design-image"
                />
                <img
                    src="/images/Design System/Typography.png"
                    alt="Typography 2"
                    className="design-image"
                />

                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>Iconography</h4>
                <img
                    src="/images/Design System/Iconography.png"
                    alt="Iconography"
                    className="design-image"
                />

                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>Spacing & Layout</h4>
                <img
                    src="/images/Design System/Spacing & Layout.png"
                    alt="Spacing & Layout"
                    className="design-image"
                />

                <h3 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "50px" }}>Prototype</h3>

                <div style={{ marginBottom: "50px" }}>
                    <a href="https://www.figma.com/proto/gNolyKwVSOLdrR8N0vkzoK/Design-System-of-MV?page-id=0%3A1&node-id=0-814&viewport=-953%2C-514%2C0.21&t=eM33P2GCOmYxsD2j-1&scaling=min-zoom&content-scaling=fixed" target="_blank" rel="noopener noreferrer">
                        {/* <img
                            src="https://www.figma.com/community/file/your-thumbnail-image-url"
                            alt="Figma Design Thumbnail"
                            width="200"
                            height="150"
                        /> */}

                        <img
                            src="/images/Design.png"
                            alt="Hello Fresh logo"
                            className="design-image"
                        />
                    </a>
                    <p style={{ margin: "0 auto" }}><a href="https://www.figma.com/proto/gNolyKwVSOLdrR8N0vkzoK/Design-System-of-MV?page-id=0%3A1&node-id=0-814&viewport=-953%2C-514%2C0.21&t=eM33P2GCOmYxsD2j-1&scaling=min-zoom&content-scaling=fixed" target="_blank" rel="noopener noreferrer" style={{ color: "black" }}>
                        View Prototype
                    </a></p>
                </div>

            </div>

        </div>
    );
}

export default DesignProjectDesignSystemPage;