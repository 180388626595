import React from 'react';
import '../styles/Projects.css';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Card from "../components/card"
import ScrollAnimation from '../components/ScrollAnimation';

const ProjectsSection = () => {
  // Dummy data for projects
  const projectsRow1 = [
    {
      id: 1,
      name: "EV Rent",
      link: "https://github.com/Pabitapun23/EV_Rent",
      image: "./images/evRent.PNG",
      description: "A mobile app (Electrical Vehicle Rent) built with React Native and Firebase, allowing users to rent out their own cars or book others' vehicles. Features include a booking system, live maps, product descriptions, search and filtering, and a favorites/save system for a seamless rental experience."
    },
    {
      id: 2,
      name: "ExploreCity",
      link: "https://github.com/Pabitapun23/ExploreCity_G07",
      image: "./images/explorecity.png",
      description: "A mobile app (Electrical Vehicle Rent) built with React Native and Firebase, allowing users to rent out their own cars or book others' vehicles. Features include a booking system, live maps, product descriptions, search and filtering, and a favorites/save system for a seamless rental experience."

    },
    {
      id: 3,
      name: "4Rent",
      link: "https://github.com/Pabitapun23/Group4_4Rent",
      image: "./images/4Rent.png",
      description: "A mobile app (Electrical Vehicle Rent) built with React Native and Firebase, allowing users to rent out their own cars or book others' vehicles. Features include a booking system, live maps, product descriptions, search and filtering, and a favorites/save system for a seamless rental experience."

    },
  ];

  const projectsRow2 = [
    {
      id: 4,
      name: "TIFF",
      link: "https://github.com/Pabitapun23/gbc-Intro-to-web/tree/main/Project-G07",
      image: "./images/tiff.png",
      description: "Redesign and developed the Toronto Internation Film Festival. Added the ticket booking system, authentication system."
    },
    {
      id: 5,
      name: "Foodzone",
      link: "https://github.com/Pabitapun23/Foodzone",
      image: "./images/FoodZone.png",
      description: 'Online food ordering platform managing workflow between customers, restaurant owners, and delivery personnel.'
    },
    {
      id: 6,
      name: "FindGrant",
      link: "https://findgrant.ca/",
      linkName: "Link",
      image: "./images/FindGrant.png",
      description: "During my internship, I worked on creating optimized design for Findgrant. It's a gateway to diverse funding opportunities in Canada."
    },
    {
      id: 7,
      name: "Pursell",
      link: "https://github.com/Pabitapun23/Pursell",
      image: "./images/Pursell.png",
      description: "Second-hand marketplace where users can purchase, sell and donate anything, anywhere and anytime."
    },
    {
      id: 8,
      name: "CofiLife",
      link: "https://cofilife.com/",
      linkName: "Link",
      image: "./images/CofiLife.png",
      description: "In my internship, I also designed and developed a UI for CofiLife using Shopify. It's a website that sells the in-house products."
    },
    {
      id: 9,
      name: "Library App",
      link: "https://github.com/Pabitapun23/gbc-Intro-to-web/tree/main/Project-G07",
      linkName: "Github",
      image: "./images/Library.jpeg",
      description: "Library book issue management system. Had collection of books that you can issue and return."
    },

  ];


  // Function to navigate to GitHub page
  const navigateToGitHub = () => {
    window.open('https://github.com/Pabitapun23', '_blank');
  };

  // Research Projects 
  const cardsData = [
    { title: 'Usability Testing Report on Hello Fresh', description: 'Conducted usability testing on the HelloFresh website to evaluate user experience, identify pain points, and recommend improvements for enhanced navigation and user satisfaction.', image: '/images/HelloFresh.png', link: 'DesignProjectDetailsPage' },
    { title: 'Case Study on Del\'s Italian Kitchen', description: 'Case study on Del’s Italian Kitchen, a restaurant lacking an online ordering system. Conducted a complete redesign to implement a user-friendly online ordering platform, enhancing the customer experience and streamlining the ordering process for greater efficiency and convenience.', image: '/images/Italian Restaurant.png', link:'DesignProjectDelsKitchen' },
    { title: 'Optimization Report for Loblaws', description: 'Optimization report for Loblaws focused on improving website usability and performance. Identified key areas for enhancement, including navigation, load times, and accessibility, with actionable recommendations to boost user engagement and overall site efficiency.', image: '/images/Loblaws.png', link: '' },
    { title: 'Design System for Music Video - "Lovesick Girls"', description: 'Created a design system, moodboard and prototype for a Kpop Music Video.', image: '/images/Website Mockup - Lovesick Girls MV.png', link: 'DesignProjectDesignSystemPage' },
    { title: 'Redesign for Toronto Bakery', description: 'Comprehensive redesign of Toronto Bakery', image: '/images/Toronto Bakery Redesign.png', link: 'DesignProjectTorontoBakery' },
    { title: 'Lululemon UX and Website Analysis', description: 'Lululemon\'s UX and Website Analysis ', image: '/images/Lululemon.png', link: '' },
  ];
  return (
    <Container id="myProjects" className='projectsSection'>
      <h2 className='certificate-title' style={{ marginBottom: "0", paddingBottom: "10px" }}>Projects</h2>
      <p style={{ fontSize: "20px", width: "80%", margin: "0 auto", paddingBottom: "50px" }}>Some of the projects that I've created consists of my research projects, case studies, UI designs, web applications and mobile applications</p>

      <h5 style={{ fontSize: "25px", width: "100%", margin: "0 70px", paddingBottom: "20px", textAlign: "left" }}>Research and Design Projects</h5>
      <p style={{ fontSize: "16px", width: "90%", margin: "0 auto", paddingBottom: "20px", textAlign: "left" }}>I did some comprehensive research projects involving case studies and usability testing on existing websites. My work focused on analyzing user experiences, identifying pain points, and providing actionable insights. I created detailed reports aimed at improving user engagement, enhancing usability, and optimizing websites to help businesses strengthen their digital presence. If you'd like to dive deeper into any of these areas, feel free to reach out. You can contact me using the information at the bottom of the page for any questions or further discussions.</p>

      <div className='CaseStudySection' style={{ width: "80%", margin: "0 auto" }}>

        <ScrollAnimation />
        <div className="card-grid">
          {cardsData.map((card, index) => (
            <Card
              key={index}
              title={card.title}
              description={card.description}
              image={card.image}
              link={card.link}
            />
          ))}
        </div>
      </div>


      <hr style={{ width: "90%" }} />
      <h5 style={{ fontSize: "25px", width: "100%", margin: "0 70px", padding: "30px 0 30px 0", textAlign: "left" }}>Web Applications</h5>

      {/* Multi-Card Carousel */}
      <ScrollAnimation />
      <div className="swiper-container">
        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          modules={[Navigation, Pagination]}
          breakpoints={{
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 4 },
          }}
        >
          {projectsRow2.map((item) => (
            <SwiperSlide key={item.id}>
              <div className="swiper-card-wrapper">
                <div class="swiper-card">
                  <img src={item.image} alt={item.name} className="swiper-image" />
                  <div className="swiper-card-body">
                    <h3 className="swiper-title">{item.name}</h3>
                    <p className="swiper-description">{item.description}</p>
                    <a href={item.link} className="swiper-button">
                      GitHub
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
{/* 
      <hr style={{ width: "90%" }} />
      <h5 style={{ fontSize: "25px", width: "100%", margin: "0 70px", padding: "30px 0 50px 0", textAlign: "left" }}>Mobile Applications</h5>
 */}

      {/* Display Projects in Rows */}
      {/* <ScrollAnimation />
      {[projectsRow1].map((projectRow, rowIndex) => (
        <Row className='projectRow' key={rowIndex}>
          {projectRow.map((project) => (
            <Col key={project.id} className="certificateContainer">
              <img
                src={project.image}
                alt={project.name}
                className="certificateImage"
                style={{ height: '80%' }}
              />
              <p style={{ fontWeight: 'bold' }}>
                {project.name} | <a target='_blank' href={project.link}>Github</a>
              </p>
            </Col>
          ))}
        </Row>
      ))} */}

      <div className='btn-div'>
        <a onClick={navigateToGitHub} className='projects-btn'>See More on GitHub</a>
      </div>

    </Container>
  );
}

export default ProjectsSection;
