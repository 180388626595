import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            // When scrollY is more than 10px from the top, the navbar will become sticky with gray background
            if (scrollY > 10) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        // <nav className="navbar sticky">
        <nav className={`navbar ${isSticky ? "sticky" : ""}`}>

            <div className="logo">
                <Link to="/" style={{ textDecoration: "none" }}><h1 style={{ color: "black" }}>Pabita Pun</h1></Link>
            </div>
            {/* <ul> */}
            <button className="toggle-button" onClick={toggleNavbar}>
                ☰
            </button>
            <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
                <li><Link to="/aboutMe">About Me</Link></li>
                <li><a href="/#myProjects">Projects</a></li>
                <li><a href="/#skills">Skills</a></li>
                <li><a href="/#myCertificates">My Certificates</a></li>
                <li><a href="/#contactMe">Contact Me</a></li>
            </ul>

        </nav>
    );
};


export default Navbar;