import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './views/Navbar';
import CoverSection from './views/CoverSection';
import './styles/App.css';
import AboutMeSection from './views/AboutMeSection';
import ContactMeSection from './views/ContactMeSection';
import ScrollToSection from './components/ScrollToSection';
import ScrollToTop from './components/ScrollToTop';
import DesignProjectDetailsPage from './views/DesignProjectDetailsPage';
import DesignProjectDesignSystemPage from './views/DesignProjectDesignSystemPage';
import DesignProjectDelsKitchen from './views/DesignProjectsDelsKitchen';
import DesignProjectTorontoBakery from './views/DesignProjectTorontoBakery';

const App = () => {
  return (
    <Router>
      <Navbar />
      <ScrollToSection />
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<CoverSection />} />
        <Route path="/aboutMe" element={<AboutMeSection />} />
        <Route path="/cardDetails/:id" element={<DesignProjectDetailsPage />} />

        <Route path="/cardDetails/HelloFresh" element={<DesignProjectDetailsPage />} />
        <Route path="/cardDetails/DesignProjectDesignSystemPage" element={<DesignProjectDesignSystemPage />} />
        <Route path="/cardDetails/DesignProjectDelsKitchen" element={<DesignProjectDelsKitchen />} />
        <Route path="/cardDetails/DesignProjectTorontoBakery" element={<DesignProjectTorontoBakery />} />
        
      </Routes>

      <ContactMeSection />   
    </Router>
  );
};

export default App;
