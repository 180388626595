import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/CardGrid.css';

const Card = ({ title, description, image, link }) => (
    <div className="card">
        <h3>{title}</h3>
        <img src={image} alt={title} className="card-image" />
        <p>{description}</p>
        {/* <Link to={`/cardDetails/${id}`} style={{ textDecoration: 'none' }}> */}
        {/* <Link to={`/cardDetails/${link}`} style={{ textDecoration: 'none' }}>
            <button className="card-button">View Project</button>
        </Link> */}

        {link ? (
            <Link to={`/cardDetails/${link}`} style={{ textDecoration: 'none' }}>
                <button className="card-button">View Project</button>
            </Link>
        ) : (
            <button className="card-button" disabled style={{ backgroundColor: '#ccc', cursor: 'not-allowed' }}>
                View Project
            </button>
        )}
    </div>
);

export default Card;