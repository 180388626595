import React from 'react';
import "../styles/Cover.css";
import '../styles/App.css';
import ProjectsSection from './ProjectsSection';
import SkillsSection from './SkillsSection';
import CertificatesSection from './CertificateSection';

const CoverSection = () => {
  return (
    <div className="cover">
      <h1>Hello, I am Pabita!</h1>
      <p style={{ fontSize: "25px", width: "65%", margin: "0 auto", paddingBottom: "280px" }}>A passionate <span style={{fontWeight: "bold"}}>User Experience Designer</span> and <span style={{fontWeight: "bold"}}>Software Engineer</span>, crafting seamless digital experiences by blending design thinking with technical expertise.</p>
      {/* <p style={{ fontSize: "18px", width: "65%", margin: "0 auto", paddingTop: "40px", paddingBottom: "40px" }}>Previously, I worked at ...</p>
      <div className='CompanyImages'>
        <img
          src="/images/Constructivist-Creations.png"
          alt="Constructive Creations logo"
          className="company-image"
        />
        <img
          src="/images/StableHacks Logo.jpeg"
          alt="StableHacks logo"
          className="company-image"
        />
        <img
          src="/images/two_ace_solutions_pvt_ltd_cover.jpeg"
          alt="Two Ace Solutions Pvt. Ltd. logo"
          className="company-image"
        />
      </div> */}

      <ProjectsSection />
      <SkillsSection />
      <CertificatesSection />
    </div>
  );
};

export default CoverSection;
