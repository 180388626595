import React from 'react';
import '../styles/DesignProjectDetailsPage.css'
import { Row, Col } from 'react-bootstrap';

const DesignProjectDelsKitchen = () => {

    return (
        <div className='DetailsPage'>
            <div className='detailsPageCover'>
                <img
                    src="/images/Italian Restaurant.png"
                    alt="Del's Italian Kitchen logo"
                    className="detail-page-image"
                />
            </div>
            <div className='detailsPageHeader'>
                <h2 className='details-page-title'>Case Study on Del's Italian Kitchen</h2>
                <p style={{ fontSize: "16px", width: "90%" }}>Case study on Del’s Italian Kitchen, a restaurant lacking an online ordering system. Conducted a complete redesign to implement a user-friendly online ordering platform, enhancing the customer experience and streamlining the ordering process for greater efficiency and convenience.
                </p>

                <div>
                    <p><strong>Context:</strong> Case Study</p>
                    <p><strong>Project Duration:</strong> 4 Weeks</p>
                    <p><strong>Team Size:</strong> 4 </p>
                    <p><strong>Tools Used:</strong> Figma, Figjam and Canva</p>
                </div>

                <h3 style={{ color: "#5c8f18", marginBottom: "0px" }}>Overview</h3>
                <p>In my information architecture course, I got to learn about the different aspects and architecture we can create while doing user experience research on websites. To explore more on this, me and my team members did the full case study on Del's Italian Kitchen, a restaurant that lacks online food ordering system on its website.</p>

                <h3 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "50px" }}>Full Case Study</h3>

                <h3 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "20px" }}>Project Brief</h3>
                <h4 style={{ color: "#5c8f18", marginBottom: "10px", marginTop: "10px" }}>Purpose</h4>
                <ul style={{ display: "flex", flexDirection: "column", listStyleType: "disc", paddingLeft: "20px" }}>
                    <li>The restaurant should have an online ordering system as this will make it more convenient for new and existing customers to have their food anytime and anywhere they want to. For example, if anyone is at work and is hungry, can order online for delivery or pickup during his/her break time. </li>
                    <li>Along with this, an online platform can chase a wider audience which lives far away from the restaurant. It will also make people connect more while providing features like promotions, discounts, or special deals.</li>
                    <li>Online ordering minimizes the workload of the staff, as customers can directly input their order into the system, whatever they want, which makes the staff work more efficiently, and there will be less chance of making incorrect orders by the staff members.</li>
                </ul>

                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>Target Audience</h4>
                <p>The main audiences of Del's Italian Kitchen restaurant are:</p>
                <ul style={{ display: "flex", flexDirection: "column", listStyleType: "disc", paddingLeft: "20px" }}>
                    <li>
                        People who love Italian Food: Del's provides various menus for Italian food. They attract the foodies who want to try an authentic or innovative variation of traditional Italian dishes.
                    </li>
                    <li>Families: Italian food is usually friendly for families. Del's offers a family-friendly environment, well enough for any family gathering, especially with their menu that comprises pasta and pizza, which are dishes that are enjoyed across all age groups.</li>
                    <li>Couples: Del's has a nice dining ambiance, and thus it is appropriate for dates and celebrations.</li>
                    <li>Business Executives: If Del's is situated near office hubs, professionals might just stop by for business lunches or even casual dinners.</li>
                    <li>Tourists: If Del's is located in a city or any other tourist-heavy area, then it could accommodate many tourists who travel around looking for a local eating experience with Italian food.</li>
                    <li>People who love mild foods: Del’s provides a range of Italian foods which are more on the milder side. So, the people whose tastebuds are more into milder flavours are also the main audiences of this restaurant.</li>
                </ul>


                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>Challenges</h4>
                <ul style={{ display: "flex", flexDirection: "column", listStyleType: "disc", paddingLeft: "20px" }}>
                    <li>
                        <strong>Issue:</strong> Poor navigation can make it hard for users to browse menus and complete orders.
                        <br />
                        <strong>Solution:</strong> Implement a user-friendly design with smooth menu browsing, cart management, and a seamless checkout process.
                    </li>
                    <li>
                        <strong>Issue:</strong> Slow load times, especially during peak hours, hurt user experience.
                        <br />
                        <strong>Solution:</strong> Optimize images, compress files, and use CDNs to improve performance while ensuring scalable server capacity.
                    </li>
                    <li>
                        <strong>Issue:</strong> Inaccurate inventory updates can lead to customers ordering out-of-stock items.
                        <br />
                        <strong>Solution:</strong> Integrate real-time inventory management and display clear "out of stock" notifications.
                    </li>
                </ul>


                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>User Needs</h4>
                <ul style={{ display: "flex", flexDirection: "column", listStyleType: "disc", paddingLeft: "20px" }}>
                    <li>
                        Customers want the system to keep records of their orders, the item lists from these orders, the product prices, deliveries and payments.
                    </li>
                    <li>
                        Users might also be concerned about information and conduct in relation to their orders, namely problems with delivery or abnormal payments.
                    </li>
                </ul>

                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>Business Goals</h4>
                <ul style={{ display: "flex", flexDirection: "column", listStyleType: "disc", paddingLeft: "20px" }}>
                    <li>
                        Including all the required information needed by customers to carry out their orders right from the website or in the app will help save time and resources by cutting down the number of support calls and messages.
                    </li>
                    <li>
                        An efficient and responsive support team allows users to easily ask questions get prompt answers and create trust which makes the possibility of experiencing repeat sales and suggestions in dreams possible.
                    </li>
                </ul>

                <div>
                    <h3 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "20px" }}>Different Flow Diagrams for Web</h3>
                    <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>Task Flow</h4>
                    <img
                        src="/images/DelKitchen/Task Flow - 1 (For Web).png"
                        alt="Del's Italian Kitchen logo"
                        className="detail-page-image"
                    />

                    <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>User Flow</h4>
                    <img
                        src="/images/DelKitchen/User Flow - 1 (For Web).png"
                        alt="Del's Italian Kitchen logo"
                        className="detail-page-image"
                    />

                    <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>Work Flow</h4>
                    <img
                        src="/images/DelKitchen/Work Flow - 1 (For Web).png"
                        alt="Del's Italian Kitchen logo"
                        className="detail-page-image"
                    />
                </div>

                <div>
                    <h3 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "20px" }}>Different Flow Diagrams for Mobile</h3>
                    <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>Task Flow</h4>
                    <img
                        src="/images/DelKitchen/Task Flow - 2 (For Mobile).png"
                        alt="Del's Italian Kitchen logo"
                        className="detail-page-image"
                    />

                    <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>User Flow</h4>
                    <img
                        src="/images/DelKitchen/User Flow - 2 (For Mobile).png"
                        alt="Del's Italian Kitchen logo"
                        className="detail-page-image"
                    />

                    <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>Work Flow</h4>
                    <img
                        src="/images/DelKitchen/Work Flow - 2(For Mobile).png"
                        alt="Del's Italian Kitchen logo"
                        className="detail-page-image"
                    />
                </div>

                <div>
                    <h3 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "20px" }}>Wireframes and Prototypes</h3>
                    <div>
                        <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>For Web</h4>
                        <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>Sketches / Paper Prototyping</h4>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "30px", width: "100%" }}>
                            <img
                                src="/images/DelKitchen/Sketches/Paper prototyping - Web.png"
                                alt="Del's Italian Kitchen logo"
                                className="detail-page-image"
                                style={{ maxWidth: "450px" }}
                            />
                            <img
                                src="/images/DelKitchen/Sketches/Paper prototyping - Web-1.png"
                                alt="Del's Italian Kitchen logo"
                                className="detail-page-image"
                                style={{ maxWidth: "450px" }}
                            />
                        </div>

                        <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>Medium Fidelity Wireflows</h4>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "30px", width: "100%" }}>
                            <img
                                src="/images/DelKitchen/Medium Fidelity Wireflows - Web.png"
                                alt="Del's Italian Kitchen logo"
                                className="detail-page-image"
                                style={{ maxWidth: "450px" }}
                            />
                            <img
                                src="/images/DelKitchen/Medium Fidelity Wireflows - Web-1.png"
                                alt="Del's Italian Kitchen logo"
                                className="detail-page-image"
                                style={{ maxWidth: "450px" }}
                            />
                        </div>

                        <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>High Fidelity Wireflows</h4>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "30px", width: "100%" }}>
                            <img
                                src="/images/DelKitchen/High Fidelity Wireflows - Web.png"
                                alt="Del's Italian Kitchen logo"
                                className="detail-page-image"
                                style={{ maxWidth: "450px" }}
                            />
                            <img
                                src="/images/DelKitchen/High Fidelity Wireflows - Web-2.png"
                                alt="Del's Italian Kitchen logo"
                                className="detail-page-image"
                                style={{ maxWidth: "450px" }}
                            />
                        </div>

                        <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>Prototyping</h4>
                        <div>
                            <a href="https://www.figma.com/proto/qBRxAWFpuzlcr8EFWG5fkd/5121_Milestone4_Group_Upsky?page-id=0%3A1&node-id=24-1097&viewport=889%2C-2117%2C0.12&t=3G49jb4wJxqLRNw5-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=24%3A1097&show-proto-sidebar=1" target="_blank" rel="noopener noreferrer">
                                <img
                                    src="/images/DelKitchen/Dels-Prototype-Web.png"
                                    alt="Toronto Bakery"
                                    className="design-image"
                                />
                            </a>
                            <p style={{ margin: "0 auto" }}><a href="https://www.figma.com/proto/qBRxAWFpuzlcr8EFWG5fkd/5121_Milestone4_Group_Upsky?page-id=0%3A1&node-id=24-1097&viewport=889%2C-2117%2C0.12&t=3G49jb4wJxqLRNw5-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=24%3A1097&show-proto-sidebar=1" target="_blank" rel="noopener noreferrer" style={{ color: "black" }}>
                                View Prototype
                            </a></p>
                        </div>
                    </div>

                    <div>
                        <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>For Mobile</h4>
                        <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>Sketches / Paper Prototyping</h4>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "30px", width: "100%" }}>
                            <img
                                src="/images/DelKitchen/Sketches/Paper prototyping - Mobile - 1.png"
                                alt="Del's Italian Kitchen logo"
                                className="detail-page-image"
                                style={{ maxWidth: "450px" }}
                            />
                            <img
                                src="/images/DelKitchen/Sketches/Paper prototyping - Mobile - 2.png"
                                alt="Del's Italian Kitchen logo"
                                className="detail-page-image"
                                style={{ maxWidth: "450px" }}
                            />
                        </div>

                        <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>Medium Fidelity Wireflows</h4>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "30px", width: "100%" }}>
                            <img
                                src="/images/DelKitchen/Medium Fidelity Wireflows - Mobile-1.png"
                                alt="Del's Italian Kitchen logo"
                                className="detail-page-image"
                                style={{ maxWidth: "450px" }}
                            />
                            <img
                                src="/images/DelKitchen/Medium Fidelity Wireflows - Mobile-2.png"
                                alt="Del's Italian Kitchen logo"
                                className="detail-page-image"
                                style={{ maxWidth: "450px" }}
                            />
                        </div>

                        <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>High Fidelity Wireflows</h4>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "30px", width: "100%" }}>
                            <img
                                src="/images/DelKitchen/High Fidelity Wireflows - Mobile-1.png"
                                alt="Del's Italian Kitchen logo"
                                className="detail-page-image"
                                style={{ maxWidth: "450px" }}
                            />
                            <img
                                src="/images/DelKitchen/High Fidelity Wireflows - Mobile-2.png"
                                alt="Del's Italian Kitchen logo"
                                className="detail-page-image"
                                style={{ maxWidth: "450px" }}
                            />
                        </div>

                        <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "30px" }}>Prototyping</h4>
                        <div style={{ marginBottom: "50px" }}>
                            <a href="https://www.figma.com/proto/qBRxAWFpuzlcr8EFWG5fkd/5121_Milestone4_Group_Upsky?page-id=3%3A866&node-id=14-2045&viewport=1475%2C-1789%2C0.12&t=eBecaZDqQAr2LPCA-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=48%3A1203" target="_blank" rel="noopener noreferrer">
                                <img
                                src="/images/DelKitchen/Dels-Prototype-Mobile.png"
                                alt="Toronto Bakery"
                                    className="design-image"
                                />
                            </a>
                            <p style={{ margin: "0 auto" }}><a href="https://www.figma.com/proto/qBRxAWFpuzlcr8EFWG5fkd/5121_Milestone4_Group_Upsky?page-id=3%3A866&node-id=14-2045&viewport=1475%2C-1789%2C0.12&t=eBecaZDqQAr2LPCA-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=48%3A1203" target="_blank" rel="noopener noreferrer" style={{ color: "black" }}>
                                View Prototype
                            </a></p>
                        </div>

                    </div>
                </div>





            </div>

        </div>
    );
}

export default DesignProjectDelsKitchen;