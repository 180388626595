import React from 'react';
import '../styles/DesignProjectDetailsPage.css'
import { Row, Col } from 'react-bootstrap';

const DesignProjectDetailsPage = () => {

    const ReportImages = Array.from({ length: 45 }, (_, i) => ({
        id: i + 1,
        src: `/images/Usability Testing Report HelloFresh/${i + 4}.png`
    }));

    return (
        <div className='DetailsPage'>
            <div className='detailsPageCover'>
                <img
                    src="/images/HelloFresh.png"
                    alt="Hello Fresh logo"
                    className="detail-page-image"
                />
            </div>
            <div className='detailsPageHeader'>
                <h2 className='details-page-title'>Usability Testing Report on Hello Fresh</h2>
                <p style={{ fontSize: "16px", width: "70%" }}>Conducted usability testing on the HelloFresh website to evaluate user experience, identify pain points, and recommend improvements for enhanced navigation and user satisfaction.
                </p>

                <div>
                    <p><strong>Context:</strong>Usability Testing Course (UXD-5124)</p>
                    <p><strong>Project Duration:</strong> 1 Weeks</p>
                    <p><strong>Team Size:</strong> 4 </p>
                    <p><strong>Tools Used:</strong> Figma, Figjam and Canva</p>
                </div>

                <h3 style={{ color: "#5c8f18", marginBottom: "0px" }}>Overview</h3>
                <p>The primary goal of this usability testing research is to evaluate the user experience and functionality of the HelloFresh website, a leading meal kit delivery service. By focusing on key tasks such as purchasing a subscription, canceling an order, deleting an account, and understanding pricing details, the study aims to uncover usability challenges and identify areas for improvement. </p>
                <p>This research seeks to understand how novice users interact with the website and navigate through its key features, particularly the purchasing flow, account management options, and pricing transparency. The findings will help inform design improvements to enhance the overall user experience, ensuring the platform effectively attracts and retains users while fostering trust and satisfaction.</p>

                <h3 style={{ color: "#5c8f18", marginBottom: "20px", marginTop: "50px" }}>Detailed Usability Testing Report</h3>
                <div className="report-gallery" style={{marginBottom: "30px"}}>
                    {ReportImages.map((image) => (
                        <img key={image.id} src={image.src} alt={`Report Image ${image.id}`} className="report-image" />
                    ))}
                </div>
            </div>

        </div>
    );
}

export default DesignProjectDetailsPage;