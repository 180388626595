import React from 'react';
import '../styles/DesignProjectDetailsPage.css'
import { Row, Col } from 'react-bootstrap';

const DesignProjectTorontoBakery = () => {

    return (
        <div className='DetailsPage'>
            <div className='detailsPageCover'>
                <img
                    src="/images/Toronto Bakery Redesign.png"
                    alt="Toronto Bakery logo"
                    className="detail-page-image"
                />
            </div>
            <div className='detailsPageHeader'>
                <h2 className='details-page-title'>Redesign for Toronto Bakery</h2>
                <p style={{ fontSize: "16px", width: "70%" }}>Comprehensive redesign of Toronto Bakery.
                </p>

                <div>
                    <p><strong>Context:</strong> Product Design, Interaction Design, Prototyping, Wireframes</p>
                    <p><strong>Project Duration:</strong> 2 Week</p>
                    <p><strong>Team Size:</strong> 1 (Individual) </p>
                    <p><strong>Tools Used:</strong> Figma and Figjam</p>
                </div>


                <h3 style={{ color: "#5c8f18", marginBottom: "0px", paddingTop: "20px" }}>Overview</h3>
                <p>During my interaction design class, we were given a task to redesign the Toronto Bakery's website which has very poor user experience and identify the pain points of the user. For this assignment, I redesigned the whole Toronto Bakery's website and app with making the navigation easy and smoother that makes the user experience intuitive.</p>

                <h3 style={{ color: "#5c8f18", marginBottom: "0px", paddingTop: "20px" }}>Problem</h3>
                <p>For this task, I analyzed the website and found that the main issue is in its cluttered information architecture, unorganized details, poor navigation and low quality images. </p>

                <h3 style={{ color: "#5c8f18", marginBottom: "0px", paddingTop: "20px" }}>Solution</h3>
                <p>To solve this issue, I analyzed the website and redesign it's information architecture through card sorting. Then, I created a low fidely and high fidelity prototypes. I asked my peers to test my high fidelity prototype and provide me some feedbacks. At last, I improved my design after their feedback and finished the design. </p>

                <h3 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "50px" }}>Card Sorting</h3>
                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>Physical Card</h4>
                <img
                    src="/images/Toronto Bakery/Card Sorting Pic.png"
                    alt="Toronto Bakery"
                    className="design-image"
                />

                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>Initial Phase: Customized Cards - All cards</h4>
                <img
                    src="/images/Toronto Bakery/CS1.png"
                    alt="Toronto Bakery"
                    className="design-image"
                />
                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>Phase 1: Randomized Cards</h4>
                <img
                    src="/images/Toronto Bakery/CS1.1.png"
                    alt="Toronto Bakery"
                    className="design-image"
                />
                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>Phase 2: Sorted based on header and footer</h4>
                <img
                    src="/images/Toronto Bakery/CS1.2.png"
                    alt="Toronto Bakery"
                    className="design-image"
                />
                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>Phase 3 : Final Sorting</h4>
                <img
                    src="/images/Toronto Bakery/CS1.3.png"
                    alt="Toronto Bakery"
                    className="design-image"
                />

                <h3 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "50px" }}>Wireframes</h3>
                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>For Mobile View</h4>

                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>Low Fidelity</h4>
                <img
                    src="/images/Toronto Bakery/LowFiMobile.png"
                    alt="Toronto Bakery"
                    className="design-image"
                />
                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>High Fidelity</h4>
                <img
                    src="/images/Toronto Bakery/HighFi1Mobile.png"
                    alt="Toronto Bakery"
                    className="design-image"
                />
                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>Final Wireframe</h4>
                <img
                    src="/images/Toronto Bakery/TorontoBakery-Mobile.png"
                    alt="Toronto Bakery"
                    className="design-image"
                />

                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>For Desktop View</h4>

                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>Low Fidelity</h4>
                <img
                    src="/images/Toronto Bakery/LowFidelityWeb.png"
                    alt="Toronto Bakery"
                    className="design-image"
                />
                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>High Fidelity</h4>
                <img
                    src="/images/Toronto Bakery/HighFidelityWeb1.png"
                    alt="Toronto Bakery"
                    className="design-image"
                />
                <h4 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "10px" }}>Final Wireframe</h4>
                <img
                    src="/images/Toronto Bakery/TorontoBakery-Web.png"
                    alt="Toronto Bakery"
                    className="design-image"
                />

                <h3 style={{ color: "#5c8f18", marginBottom: "0px", marginTop: "50px" }}>Prototype</h3>

                <div style={{ marginBottom: "50px" }}>
                    <a href="https://www.figma.com/proto/5x7Hr3Baut0JvwT8041FUY/Interaction-Design---Design-Challenge---Toronto-Cupcake-(Pabita-Pun---n01684158?page-id=0%3A1&node-id=92-3253&viewport=1134%2C-2042%2C0.4&t=QbnqLWQf8f6wNCTQ-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=3%3A18" target="_blank" rel="noopener noreferrer">
                        <img
                            src="/images/Toronto Bakery/TorontoBakery-Mobile.png"
                            alt="Toronto Bakery"
                            className="design-image"
                        />
                    </a>
                    <p style={{ margin: "0 auto" }}><a href="https://www.figma.com/proto/5x7Hr3Baut0JvwT8041FUY/Interaction-Design---Design-Challenge---Toronto-Cupcake-(Pabita-Pun---n01684158?page-id=0%3A1&node-id=92-3253&viewport=1134%2C-2042%2C0.4&t=QbnqLWQf8f6wNCTQ-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=3%3A18" target="_blank" rel="noopener noreferrer" style={{ color: "black" }}>
                        View Prototype
                    </a></p>

                    <a href="https://www.figma.com/proto/5x7Hr3Baut0JvwT8041FUY/Interaction-Design---Design-Challenge---Toronto-Cupcake-(Pabita-Pun---n01684158?page-id=3%3A2&node-id=80-2490&viewport=953%2C-1136%2C0.2&t=XLlSbqRAfJD2Krqo-1&scaling=scale-down&content-scaling=fixed" target="_blank" rel="noopener noreferrer">
                        <img
                            src="/images/Toronto Bakery/TorontoBakery-Web.png"
                            alt="Toronto Bakery"
                            className="design-image"
                        />
                    </a>
                    <p style={{ margin: "0 auto" }}><a href="https://www.figma.com/proto/5x7Hr3Baut0JvwT8041FUY/Interaction-Design---Design-Challenge---Toronto-Cupcake-(Pabita-Pun---n01684158?page-id=3%3A2&node-id=80-2490&viewport=953%2C-1136%2C0.2&t=XLlSbqRAfJD2Krqo-1&scaling=scale-down&content-scaling=fixed" target="_blank" rel="noopener noreferrer" style={{ color: "black" }}>
                        View Prototype
                    </a></p>
                </div>

            </div>

        </div>
    );
}

export default DesignProjectTorontoBakery;