import React from 'react';
import '../styles/AboutMe.css';
import BackgroundSection from './BackgroundSection';

const AboutMeSection = () => {
  return (
    <div className="aboutMe" id="aboutMe">
      <div className="left-section">
        <h2>About Me</h2>
        <p style={{ fontSize: "16px", marginBottom: "0" }}>
          I’m Pabita, a second-semester post-graduate student in User Experience Design at <span style={{ fontWeight: "bold" }}>Humber Polytechnic</span>.
        </p>
        <p style={{ fontSize: "16px", marginBottom: "0" }}>
          I am a dedicated <span style={{ fontWeight: "bold" }}>User Experience Designer</span> with a <span style={{ fontWeight: "bold" }}>Software Engineering</span> background, passionate about <span style={{ fontStyle: "italic" }}>creating intuitive, user-centered digital experiences</span>. I combine my love for design with a strong technical foundation to deliver solutions that not only look great but are also backed by solid, scalable code. With a robust understanding of both front-end and back-end development, I take a holistic approach to every project, ensuring seamless integration of design and functionality.
        </p>
        <p style={{ fontSize: "16px", marginBottom: "0" }}>
          My expertise spans <span style={{ fontWeight: "bold" }}> Mobile Application Development, User Experience Design, and full-stack development</span>, with a particular focus on crafting interactive interfaces and optimizing back-end systems. I bring a deep understanding of the development process and thrive in collaborative environments, working effectively with both design and engineering teams to transform ideas into functional products.
        </p>
        <p style={{ fontSize: "16px"}}>
          Currently, through my post-graduate degree, I am committed to continuously honing my skills to stay ahead of the curve in this dynamic field. Whether designing engaging web and mobile applications or developing robust back-end solutions, my goal is always to <span style={{ fontStyle: "italic" }}>create meaningful experiences that solve real user problems and meet business objectives.</span>
        </p>
      </div>
      <div className="right-section">
        <img
          src="/images/Pabita.jpg"
          alt="PABITA PUN"
          className="profile-image"
          style={{ border: "1px solid white", borderRadius: "5px" }}
        />
      </div>

      <BackgroundSection />
    </div>
  );
};

export default AboutMeSection;
