import { useEffect } from 'react';

const ScrollAnimation = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target); 
          }
        });
      },
      {
        threshold: 0.5, 
      }
    );

    const cards = document.querySelectorAll('.card');
    cards.forEach(card => {
      observer.observe(card);
    });

    const swiperCards = document.querySelectorAll('.swiper-card');
    swiperCards.forEach(card => {
      observer.observe(card);
    });

    return () => {
      cards.forEach(card => {
        observer.unobserve(card);
      });

      swiperCards.forEach(card => {
        observer.unobserve(card);
      });
    };
  }, []);

  return null;
};

export default ScrollAnimation;
