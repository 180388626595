import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faBuildingColumns, faLaptop, faFile } from '@fortawesome/free-solid-svg-icons';
import '../styles/Background.css'

const workData = [
  {
    icon: faLaptop,
    institution: 'Two Ace Solutions Pvt. Ltd.',
    position: 'Internship',
    duration: 'June 2022 - July 2023'
  },
  {
    icon: faLaptop,
    institution: 'StableHacks',
    position: 'Software Developer Intern (Coop)',
    duration: 'May 2024 - July 2024'
  },
  {
    icon: faLaptop,
    institution: 'Constructive Creations',
    position: 'Contract work',
    duration: 'September 2024 - Present'
  },
];

const educationData = [
  {
    icon: faGraduationCap,
    institution: 'Gandaki College of Engineering and Science',
    degree: 'Bachelors of Software Engineering',
    duration: 'September 2017 - August 2022'
  },
  {
    icon: faGraduationCap,
    institution: 'George Brown College',
    degree: 'Post Graduate in Mobile Application Development and Strategies',
    duration: 'September 2023 - August 2024'
  },
  {
    icon: faBuildingColumns,
    institution: 'Humber Polytechnic',
    degree: 'Post Graduate in User Experience Design',
    duration: 'September 2024 - Present'
  },
];

// const pdfFiles = [
//   { icon: faFile, name: 'Transcript', url: '/docs/Academic Transcript.pdf' },
//   { icon: faFile, name: 'Proof of Enrollment', url: '/docs/Proof of Enrollment.pdf' },
// ];

const BackgroundSection = () => {
  return (
    // <div className="backgroundSection pt-80" id="background">
    <div className="backgroundSection" id="background">
      <h2>Background</h2>

      <h5 style={{ fontSize: "25px", width: "100%", margin: "0 70px", paddingBottom: "20px", textAlign: "left" }}>Work Experience</h5>

      <div className='backgroundContent'>
        {workData.map((item, index) => (
          <div key={index} className="iconContainer">
            <FontAwesomeIcon icon={item.icon} size="3x" className='icon' />
            <h4>{item.institution}</h4>
            {item.degree && <h5>{item.degree}</h5>}
            {item.position && <h5>{item.position}</h5>}
            <p>{item.duration}</p>
          </div>
        ))}
      </div>

      <h5 style={{ fontSize: "25px", width: "100%", margin: "20px 70px 0 70px", paddingBottom: "20px", textAlign: "left" }}>Education</h5>

      <div className='backgroundContent'>
        {educationData.map((item, index) => (
          <div key={index} className="iconContainer">
            <FontAwesomeIcon icon={item.icon} size="3x" className='icon' />
            <h4>{item.institution}</h4>
            {item.degree && <h5>{item.degree}</h5>}
            {item.position && <h5>{item.position}</h5>}
            <p>{item.duration}</p>
          </div>
        ))}
      </div>

      {/* <h2 style={{paddingTop: '50px'}}>Documents</h2> */}

      {/* <div className='docContainer'>
      
        {pdfFiles.map((file, index) => (
          <div key={index} className="iconContainer"> 
            <FontAwesomeIcon icon={file.icon} size="3x" className='icon'/>
            <ul>
              <li>
                <a href={file.url} target="_blank" rel="noopener noreferrer"> <h3>{file.name}</h3></a>
              </li>
            </ul>
          </div>
        ))}
      </div> */}
    </div>
  );
}

export default BackgroundSection;
